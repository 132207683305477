* {
  margin: 0;
  padding: 0;
  font-family: inherit;
}

.newsletter-container {
  width: 450px;
  padding: 30px;
  margin: 20px auto;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  margin-bottom: 8%;
}

.newsletter-container p {
  font-size: 0.8em;
  font-weight: 300;
  margin-bottom: 25px;
}

.newsletter-container h1 {
  font-size: 40px;
}

.form-input {
  margin-bottom: 25px;
  display: block;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border: none;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
  -webkit-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
  font-family: Arial, Helvetica, sans-serif;
}

.form-input-text {
  display: block;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border: none;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
  -webkit-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
  font-family: Arial, Helvetica, sans-serif;
}

.btn {
  display: block;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;

  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border: none;
  box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
  -webkit-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 3%;

  background-color: darkgoldenrod;
  color: rgb(15, 15, 15);
  font-weight: bold;
  font-size: 1.4rem;
  padding: 1%;
}

.btn:hover {
  cursor: pointer;
  background-color: rgb(250, 201, 77);
}

.contact-container {
  margin-top: 5%;
  font-family: Arial, Helvetica, sans-serif;
}

.message-send {
  text-align: center;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 2%;
  font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 700px) {
  .newsletter-container {
    width: 300px;
    padding: 5%px;
    margin: 20px auto;
  }

  .newsletter-container h1 {
    font-size: 30px;
  }

  .lable {
    padding: 5%;
    font-weight: bolder;
    font-family: Arial, Helvetica, sans-serif;
  }

  .form-input {
    margin-bottom: 15px;
    display: block;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border: none;
    box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
    -moz-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
    -webkit-box-shadow: inset 0px 1px 1px rgba(0, 0, 0, 0.33);
    font-family: Arial, Helvetica, sans-serif;
  }

  .form-input-text {
    display: block;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border: none;

    font-family: Arial, Helvetica, sans-serif;
  }

  .btn {
    display: block;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border: none;

    font-family: Arial, Helvetica, sans-serif;
    margin-top: 3%;

    background-color: darkgoldenrod;
    color: rgb(0, 0, 0);
  }

  .btn:hover {
    cursor: pointer;
    background-color: rgb(250, 201, 77);
  }

  .contact-container {
    margin-top: 5%;
    font-family: Arial, Helvetica, sans-serif;
  }

  .message-send {
    text-align: center;
    margin: auto;
    margin-top: 2%;
    margin-bottom: 2%;
    font-family: Arial, Helvetica, sans-serif;
  }
}
