.home {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-columns: 60% 40%;
  color: darkgoldenrod;
  min-height: 95%;
  max-width: 98%;
}

.title {
  font-size: 20vw;
  font-family: "Fredericka the Great", cursive;
  line-height: 80%;
  margin: 5% 15% 0 25%;
}

.intro {
  justify-content: end;
  margin: 10% 15%;
  text-align: center;
  font-size: 1.5vw;
  padding: 0 10%;
}

@media only screen and (max-width: 800px) {
  .home {
    top: -50px;
    display: block;
    z-index: 10;
  }

  .title {
    align-items: center;

    font-size: 25vw;
  }

  .intro {
    font-size: 3.5vw;

    margin-bottom: 8%;
  }
}
