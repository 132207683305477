.footer-wrapper {
  position: sticky;
  margin-top: 5%;

  background-color: inherit;

  z-index: 1;
}
.footer-text {
  margin: auto;
  text-align: center;
}

@media only screen and (max-width: 700px) {
  .footer-text {
    font-size: 0.7em;
    text-align: center;
  }
}
