.about-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 5%;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 2%;
}

.col {
  flex: 1;
}

.about-tekst {
  padding-right: 20%;
}

.ul-list {
  text-decoration: none;
  list-style-type: none;
  padding-right: 10%;
}

.hoofd {
  font-size: 2em;
  line-height: 5%;
  padding-bottom: 3%;
}

.bio {
  font-size: 1em;
}

.tussen {
  font-size: 1.5em;
}

.jaar {
  font-style: italic;
  font-size: 0.8em;
}

.image-mo {
  margin-top: 8%;
  margin-left: 25%;
  margin-right: auto;
  border-radius: 5%;
}

.a2:hover {
  color: darkgray;
  cursor: pointer;
}

@media only screen and (max-width: 700px) {
  .about-container {
    display: block;

    font-size: 0.7em;
    margin-top: 5%;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 2%;
  }

  .image-mo {
    margin-left: 0;
    border-radius: 5%;
  }

  .about-tekst {
    margin-bottom: 10%;
  }

  .hoofd {
    font-size: 2em;
    line-height: 5%;
    padding-bottom: 3%;
  }

  .col {
    padding: 0 10%;
    margin-left: 4%;
    margin-bottom: 5%;
  }
}
