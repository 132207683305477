body {
  margin: 0;
  padding: 0;

  background-color: rgb(15, 15, 15);
  color: darkgoldenrod;
  overflow-x: hidden;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
  color: darkgoldenrod;
}

a:hover {
  color: gold;
}
