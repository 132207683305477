.Gallery {
  margin: auto;
  max-width: 70%;
  height: 100%;
  align-items: center;
  width: 70%;
  overflow: hidden;
}

.title-paintings {
  justify-content: end;
  margin: 5% auto;
  text-align: center;
  color: darkgoldenrod;
}
