.container {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  z-index: 5;
  background-color: rgb(15, 15, 15);
}

.Header {
  max-height: 70px;
  width: 100vw;
  font-size: 2vw;
  display: flex;
  justify-content: space-between;
  color: darkgoldenrod;
  margin-bottom: 10%;
}

.Logo-container {
  grid-area: logo;
  height: 100%;
  width: 60px;
  padding: 25px;
}

.Nav {
  width: 50%;
  height: 100%;
  display: flex;
  margin: 2% 3% 0;
  justify-content: flex-end;

  align-items: flex-end;
  justify-items: flex-end;
}
.navLink {
  justify-content: space-between;
  transition: 0.5s;
  text-decoration: none;
  margin-right: 3%;
}
.navLink:hover {
  transform: scale(1.1);
}

.Burger {
  display: none;
  grid-area: burger;
  margin: 0 20px 0 0;
  padding: 0;
  justify-self: end;
  font-size: 40px;
  border: none;
  background: none;
  outline: none;
  transition: 0.1s;
}
.Burger:active {
  transform: scale(1.2);
}

@media only screen and (max-width: 700px) {
  .Header {
    grid-template-areas: "logo burger" "nav nav";
  }
  .Nav {
    grid-template-columns: repeat(4, auto);
    margin-top: 3%;
    font-size: 4vw;
    margin-right: 3%;
  }

  .navLink {
    padding: 3% 0 3% 3%;
  }

  .Burger {
    display: inline;
    color: darkgoldenrod;
  }

  .Logo-container {
    grid-area: logo;
    height: 100%;
    width: 20px;

    margin-left: 3%;
  }
}

.NavAnimation-enter {
  opacity: 0;
  transform: scale(0.5);
}
.NavAnimation-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 350ms, transform 350ms;
}
.NavAnimation-exit {
  opacity: 1;
}
.NavAnimation-exit-active {
  opacity: 0;
  transform: scale(0.5);
  transition: opacity 350ms, transform 350ms;
}

@media (max-width: 300px) {
  .Header {
    grid-template-areas: "logo burger" "nav nav";
    margin-right: 2%;
    font-size: 0.8em;
    width: 100%;
    margin: 0;
    overflow: hidden;
  }
}
